<template>
<router-view />
</template>

<style>
body {
    margin: 0 auto;
}

#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #333;
    border-top: 1px solid #fff;
}

a {
    color: #333;
    text-decoration: none;
    cursor: pointer;
}
.dev{
  font-size: 12px;
  color: #ccc;
}
.custom-width {
    width: 80%;
    min-width: 900px;
    max-width: 1200px;
    margin: auto;
}
.imgs{
    height:300px;
    background:#4c89f4
}
</style>
