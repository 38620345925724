import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { ajax } from 'jquery'
import ElementPlus from 'element-plus';
import 'element-plus/lib/theme-chalk/index.css';

let app = createApp(App)

// console.log("app",app)

app.config.globalProperties.clearAllCookie = function () {
  var keys = document.cookie.match(/[^ =;]+(?=\=)/g);
  if(keys) {
    for(var i = keys.length; i--;)
      document.cookie = keys[i] + '=0;expires=' + new Date(0).toUTCString()
  }
}


let domin = "https://www.wzsosu.com";

app.domin = domin
let errfuc = function (res) {
  let tips = "";
  switch (res.result) {
    case "message":
      tips = "异常信息";
      break;
    case "exception":
      tips = "生成异常";
      break;
    case "nologin":
      tips = "尚未登录";
      location.href = "/#/login"
      break;
    case "errorinfo":
      tips = "无查询结果";
      break;
    case "nullopenid":
      tips = "该账号异常，请业务员联系客服";
      break;
    case "noexist":
      tips = "用户不存在";
      break;
    case "errorpsw":
      tips = "密码错误";
      break;
    case "exsitphone":
      tips = "该号码已存在";
      break;
    default:
      tips = JSON.stringify(res)
      break;
  }
  alert(tips)
  return false

  


};
app.wget = function ajx(obj) {
  ajax({
    url: obj.url,
    method: "GET",
    headers: {
      "content-type": "application/x-www-form-urlencoded",
    },
    data: obj.data,
  })
    .then((res) => {
      if (res.result !== "success") {
        errfuc(res);
      }

      obj.succ(res);
    })
    .catch((err) => {
      console.log(err);
    });
}
app.wpost = function ajx(obj) {
  ajax({
    url: obj.domin?obj.domin + obj.url:obj.url,
    method: "POST",
    headers: {
      "content-type": "application/x-www-form-urlencoded",
    },
    data: obj.data,
  })
    .then((res) => {
      if (res.result !== "success") {
        errfuc(res);
      }

      obj.succ(res);
    })
    .catch((err) => {
      console.log(err);
    });
}

app.use(store).use(router).use(ElementPlus).mount('#app')
