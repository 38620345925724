import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Index',
    component: () => import('../views/Index.vue'),
    children: [
      {
        path: 'home',
        name: 'Home',
        component: () => import('../views/Home.vue'),
        meta:{
          title:'微在线网络'
        }
      }, {
        path: 'about',
        name: 'About',
        component: () => import('../views/About.vue')
      },
      {
        path: 'shop/:id',
        name: 'Shop',
        component: () => import('../views/Shop.vue')
      },
      {
        path: 'classes/:id',
        name: 'Classes',
        component: () => import('../views/Classes.vue')
      },
      {
        path: 'cartInBuy/:id',
        name: 'CartInBuy',
        component: () => import('../views/CartInBuy.vue')
      },
      {
        path: 'cart',
        name: 'Cart',
        component: () => import('../views/Cart.vue')
      },
      {
        path: 'center',
        name: 'Center',
        component: () => import('../views/Center.vue')
      },
      {
        path: 'certification',
        name: 'Certification',
        component: () => import('../views/Certification.vue')
      },
      
      {
        path: 'details/:id',
        name: 'Details',
        component: () => import('../views/Details.vue')
      },
      {
        path: 'carton',
        name: 'Carton',
        component: () => import('../views/Carton.vue')
      },
      {
        path: 'secondhand',
        name: 'Secondhand',
        component: () => import('../views/Secondhand.vue')
      },
      {
        path: 'productOrderin',
        name: 'ProductOrderin',
        component: () => import('../views/ProductOrderin.vue')
      },]
  },
  {
    path: '/shopManage',
    name: 'ShopManage',
    component: () => import('../views/ShopManage.vue'),
    children: [

      {
        path: 'walflist',
        name: 'Walflist',
        component: () => import('../views/Walflist.vue')
      },
      {
        path: 'walfproduct',
        name: 'Walfproduct',
        component: () => import('../views/Walfproduct.vue')
      },
      {
        path: 'cityServe',
        name: 'CityServe',
        component: () => import('../views/CityServe.vue')
      },

    ]
  },
  {
    path: '/infos2',
    name: 'Infos2',
    component: () => import('../views/Infos2.vue')
  },
  {
    path: '/infos',
    name: 'Infos',
    component: () => import('../views/Infos.vue')
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('../views/Register.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
